import { AppModuleAnalyticsKeys } from '@app/analytics/analytics.models';

export const AnalyticsPortalKeys: AppModuleAnalyticsKeys = {
    category: 'Portal',
    pages: {
        homeDashboard: 'Home dashboard'
    }
};

export default AnalyticsPortalKeys;
