import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { AnalyticsService } from '@app/analytics/analytics.service';
import { NavigationService } from '@app/portal/navigation/navigation.service';
import AnalyticsPortalKeys from '@app/portal/portal-analytics';
import { KeycloakService } from '@app/security/keycloak/keycloak.service';
import { patchState, signalState } from '@ngrx/signals';

export type DashboardState = {
    showAnimatedBackground: boolean;
    apps: Route[];
};

@Component({
    selector: 'sphere-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: [
        trigger('slideInCards', [
            transition(':enter', [
                query(
                    '.plugin-card',
                    [
                        style({ transform: 'translateY(100%)', opacity: 0 }),
                        stagger('35ms', [animate('180ms cubic-bezier(0.0, 0.0, 0.2, 1)', style({ transform: 'translateY(0%)', opacity: 1 }))])
                    ],
                    { optional: true }
                )
            ])
        ])
    ],
    standalone: false
})
export class DashboardComponent implements OnInit {
    public readonly state = signalState<DashboardState>({
        showAnimatedBackground: false,
        apps: []
    });

    constructor(
        private keycloak: KeycloakService,
        private nav: NavigationService,
        private analytics: AnalyticsService
    ) {
        this.analytics.page(AnalyticsPortalKeys.category, AnalyticsPortalKeys.pages.homeDashboard);
    }

    ngOnInit() {
        patchState(this.state, {
            apps: this.keycloak.getUserApps(this.nav.apps)
        });
    }

    setShowAnimatedBackground() {
        patchState(this.state, state => ({
            showAnimatedBackground: !state.showAnimatedBackground
        }));
    }
}
