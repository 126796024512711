import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { DashboardComponent } from '@app/portal/dashboard/dashboard.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { FunctionWrapperModule } from '@shared/pipes/function-wrapper/function-wrapper.module';
import { AppCardComponent } from './app-card/app-card.component';
import { CoreServicesModule } from '@app/shared/services/core-services.module';

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatRippleModule,
        MatIconModule,
        MatMenuModule,
        RouterModule,
        CoreServicesModule,
        LetDirective,
        FunctionWrapperModule,
        NgOptimizedImage,
        MatDivider,
        MatSlideToggle
    ],
    declarations: [DashboardComponent, AppCardComponent],
    exports: [DashboardComponent]
})
export class DashboardModule {}
