import { NgModule } from '@angular/core';
import { ExtraOptions, Route, RouterModule, Routes } from '@angular/router';
import {
    calendarManagerApp,
    criticalListsApp,
    curveBuilderApp,
    curveTraceabilityApp,
    dataManagementApp,
    dataSupportApp,
    internalToolsApp,
    naPowerMarketsApp,
    userManagerApp,
    workflowApp
} from '@app/app-routes';
import { dataInsightsApp } from '@app/app-routes/data-insights';
import { FORBIDDEN, NOT_FOUND, UNAUTHORIZED } from '@app/generic-routes';
import { DashboardComponent } from '@app/portal/dashboard/dashboard.component';
import { PrivacyPolicyComponent } from '@app/portal/privacy-policy/privacy-policy.component';
import { ForbiddenComponent } from '@app/portal/redirection/forbidden/forbidden.component';
import { PageNotFoundComponent } from '@app/portal/redirection/page-not-found/page-not-found.component';
import { SERVICES_UNAVAILABLE_PATH, ServicesUnavailableComponent } from '@app/portal/redirection/services-unavailable/services-unavailable.component';
import { UnauthorizedComponent } from '@app/portal/redirection/unauthorized/unauthorized.component';
import { FeatureConfiguration } from '@app/service/app-config.service';
import { AuthGuard } from './security/auth.guard';
import { kplerApp } from '@app/app-routes/kpler';

export interface AppRouteData {
    name: string;
    title: string;
    tagLine: string;
    description: string;
    logoSvg: string;
    logoSvgActive: string;
    accessRole?: string | string[];
    cardBackground: string;
    hiddenApp?: boolean;
    requiresFeature?: keyof FeatureConfiguration;
}

export const APP_ROUTES: Route[] = [
    dataManagementApp,
    dataSupportApp,
    dataInsightsApp,
    criticalListsApp,
    curveBuilderApp,
    curveTraceabilityApp,
    workflowApp,
    calendarManagerApp,
    userManagerApp,
    kplerApp,
    naPowerMarketsApp,
    internalToolsApp
];

const DASHBOARD_PATH = 'dashboard';

const routes: Routes = [
    {
        path: '',
        canActivateChild: [AuthGuard], // user must be authenticated to access all routes KEYCLOAK PENDING
        data: { title: 'Home' },
        children: [
            {
                path: DASHBOARD_PATH,
                component: DashboardComponent,
                data: { title: 'Dashboard', noPadding: true }
            },
            {
                path: 'privacy-policy',
                component: PrivacyPolicyComponent,
                data: { title: 'Privacy policy' }
            },
            ...APP_ROUTES,
            {
                path: NOT_FOUND.path,
                component: PageNotFoundComponent,
                data: { title: 'Not found' }
            },
            {
                path: UNAUTHORIZED.path,
                component: UnauthorizedComponent,
                data: { title: 'Unauthorized' }
            },
            {
                path: FORBIDDEN.path,
                component: ForbiddenComponent,
                data: { title: 'Forbidden' }
            },
            {
                path: SERVICES_UNAVAILABLE_PATH,
                component: ServicesUnavailableComponent,
                data: { title: 'Services unavailable' }
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: DASHBOARD_PATH,
                data: { title: 'Dashboard' }
            },
            {
                path: '**',
                redirectTo: `/${NOT_FOUND.path}`
            }
        ]
    }
];

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    // DEBUG CONFIGURATION
    // imports: [RouterModule.forRoot(routes, { enableTracing: true, scrollPositionRestoration: 'enabled' })],
    imports: [
        // TODO: Check if onSameUrlNavigation: 'reload' is still necessary after @Gorka refactors Curve Viewer (component store) because
        // this config is being added due to a bug from that component: https://drillinginfo.atlassian.net/browse/DGP-3983
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload',
            bindToComponentInputs: true
        })
    ],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule {}
