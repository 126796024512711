@if (state.showAnimatedBackground()) {
    <video class="video-bg" autoplay muted loop>
        <source src="../../assets/video/cityscape_1080.mp4" type="video/mp4" />
    </video>
} @else {
    <!--    <div class="image-bg">-->
    <!--        &lt;!&ndash;        <img alt="cityscape" ngSrc="../../assets/images/cityscape.jpg" fill />&ndash;&gt;-->
    <!--    </div>-->
}

<div class="dashboard">
    @if (state.apps()?.length) {
        <div class="dashboard__grid" [@slideInCards]>
            @for (app of state.apps(); track $index) {
                <sphere-app-card data-pendo="appTile" [app]="app"></sphere-app-card>
            }
        </div>
    } @else {
        <p class="no-apps-message">We're sorry, you are not authorized to use any application. Please contact your system administrator.</p>
    }
</div>

<!--<section class="dashboard-bottom-section">-->
<!--    <mat-divider [style.margin-bottom.px]="20" />-->
<!--    <mat-slide-toggle [checked]="state.showAnimatedBackground()" (toggleChange)="setShowAnimatedBackground()">Animated background</mat-slide-toggle>-->
<!--</section>-->
