<!-- START: Search | Content Sidebar -->
<aside class="app__sidebar-inner" [ngClass]="{ 'app__sidebar-inner--collapsed': state.collapsedSidenav() }">
    <div class="app__sidebar-inner__grid">
        <div class="app__sidebar-inner_left">
            <a routerLink="/" matTooltip="Home" matTooltipPosition="right">
                <div class="app__sidebar-inner_top-logo"></div>
            </a>
            <div *ngIf="!(isShell$ | ngrxPush)" [style.text-align]="'center'" [@slideInOutV] data-pendo="apps">
                <div class="app_sidebar-inner_divider app_sidebar-inner__link"></div>
                <ng-container *ngFor="let app of getAllApps">
                    <a
                        *ngIf="app.data?.isAvailable"
                        mat-icon-button
                        class="app_sidebar-inner__link"
                        [matTooltip]="app.data.name"
                        matTooltipPosition="right"
                        [routerLink]="['/' + app.path]"
                        routerLinkActive="app_sidebar-inner__link__active"
                        #rla="routerLinkActive"
                    >
                        <div
                            *ngIf="app.data.logoSvg; else noLogo"
                            class="app-sidebar-inner__link__logo"
                            style="background-size: cover"
                            [style.background-image]="
                                rla.isActive ? (app.data.logoSvgActive | wrapFn: getLogo) : (app.data.logoSvg | wrapFn: getLogo)
                            "
                        ></div>
                        <ng-template #noLogo>
                            <mat-icon>settings</mat-icon>
                        </ng-template>
                    </a>
                </ng-container>
            </div>

            <div class="spacer"></div>

            <div class="app__help-center-toggle" matTooltip="Help center" matTooltipPosition="right">
                <sphere-help-center-button />
            </div>
        </div>

        <div class="app__sidebar-inner_right" [ngClass]="{ 'app--sidebar-inner--right--collapsed': state.collapsedSidenav() }">
            <sphere-sidebar-nav class="app--sidebar-inner--right--navigation" [collapsedSidenav]="state.collapsedSidenav()"></sphere-sidebar-nav>

            <div class="app__sidebar-inner--right--footer">
                <sphere-ws-status [onlyErrors]="true"></sphere-ws-status>
                <button
                    class="app--sidebar-inner--right--collapse-toggle"
                    mat-icon-button
                    (click)="toggleSidenavState()"
                    matTooltipPosition="above"
                    data-pendo="compact side nav"
                    [matTooltip]="state.collapsedSidenav() ? 'Expand side navigation' : 'Compact side navigation'"
                >
                    @if (state.collapsedSidenav()) {
                        <mat-icon data-cy="expand">keyboard_tab</mat-icon>
                    } @else {
                        <mat-icon data-cy="collapse">first_page</mat-icon>
                    }
                </button>
            </div>
        </div>
    </div>
</aside>
<main class="app__main-content_content">
    <!-- START: Top bar -->
    <mat-toolbar class="app_main-content_topbar">
        <div class="app_main-content_topbar_left">
            <sphere-breadcrumbs />
        </div>
        <div class="spacer"></div>
        <shell-environment-indicator />
        <mat-divider vertical [style.height.%]="80" [style.margin-left.px]="15" />
        <div class="app_main-content_topbar_right">
            <div class="app__notification-center-toggle" matTooltip="Notification center" matTooltipPosition="below">
                <sphere-notification-center-toggle></sphere-notification-center-toggle>
            </div>
            <sphere-greeting />
        </div>
    </mat-toolbar>
    <!-- END: Top bar -->

    @if (state.routerNavigationLoading()) {
        <div [@fadeInOut] class="app__router-navigation-spinner">
            <sphere-full-page-loading-placeholder />
        </div>
    }
    <!-- START: Main content / router outlet -->
    <div class="app__router-outlet-wrapper" [ngClass]="{ 'app__router-outlet-wrapper--no-padding': noPaddingRoute() }">
        <router-outlet></router-outlet>
    </div>
    <!-- END: Main content / router outlet -->
</main>
