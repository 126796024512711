<mat-card
    appearance="raised"
    class="app-card mat-elevation-z4"
    [ngClass]="{ 'app-card--disabled': disabled() }"
    [ngStyle]="{ backgroundColor: cardHover() ? app().data?.['cardBackground'] : '#31313150' }"
    matRipple
    [routerLink]="['/' + app().path]"
    (mouseover)="cardHover.set(true)"
    (mouseleave)="cardHover.set(false)"
>
    <mat-card-header>
        <div
            *ngrxLet="app() | wrapFn: sanitizeCSS; let css"
            mat-card-avatar
            class="card-avatar"
            [ngStyle]="{
                opacity: cardHover() ? 0.75 : 1,
                backgroundColor: cardHover() ? app().data['cardBackground'] : null
            }"
            [style.background-size]="'cover'"
            [style.background-image]="css"
        ></div>
        <mat-card-title>
            <span *ngrxLet="app().data['name'] | wrapFn: sanitizeHTML; let html" [innerHTML]="html" [style.font-weight]="400"></span>
        </mat-card-title>
        <mat-card-subtitle>{{ app().data['tagLine'] | uppercase }}</mat-card-subtitle>
    </mat-card-header>
</mat-card>
