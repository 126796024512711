import { Injectable } from '@angular/core';
import { TimeSeriesDetailsFacade } from '@shared/api/data-management/time-series-details.facade';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { TimeSeriesActions } from '@app/data-management/time-series/+state/time-series.actions';
import { mergeMap, of } from 'rxjs';
import { TimeSeriesDataRequest, TimeseriesService } from '@shared/api/data-management/generated';
import { catchError, filter, map } from 'rxjs/operators';
import { selectTimeSeriesDetailsForSymbol } from '@app/data-management/time-series/+state/time-series.selectors';
import { format, formatISO } from 'date-fns';
import { SymbolService } from '@app/data-management/symbols/symbol.service';
import splitSymbolCodeAndTenor from '@app/data-management/symbols/utility/splitSymbolCodeAndTenor';
import { ContextSnackbarService } from '@shared/services/context-snackbar.service';
import { ErrorMsgUtilsService } from '@shared/utils/error-msg-utils.service';

@Injectable()
export class TimeSeriesEffects {
    /**
     * Time series details loading
     */
    loadTimeSeriesDetailsIfNotLoaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeSeriesActions.loadTimeSeriesDetails),
            concatLatestFrom(({ symbol }) => this.store.select(selectTimeSeriesDetailsForSymbol(symbol))),
            filter(([{ symbol, force }, details]) => force || !details),
            mergeMap(([{ symbol }, details]) => {
                const modifiedSymbol = splitSymbolCodeAndTenor(symbol);
                return this.timeSeriesDetailsFacade.getTimeSeriesDetailsUsingGET(modifiedSymbol.symbolCode).pipe(
                    map(details => TimeSeriesActions.loadTimeSeriesDetailsSuccess({ symbol, details })),
                    catchError(error => of(TimeSeriesActions.loadTimeSeriesDetailsError({ symbol, error })))
                );
            })
        )
    );

    /**
     * Time series data loading
     */
    singleLoadTimeSeriesData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeSeriesActions.loadTimeSeriesData),
            mergeMap(({ symbol, range, field }) => {
                const request: TimeSeriesDataRequest = {
                    range,
                    inputs: [{ symbol, field }]
                };
                return this.timeSeries.getTimeSeriesDatas({ timeSeriesDataRequest: request }).pipe(
                    map(dataResponses => TimeSeriesActions.loadTimeSeriesDataSuccess({ symbol, field, data: dataResponses[0] })),
                    catchError(error => {
                        const msg = ErrorMsgUtilsService.getErrorMsg(error, 'get', 'time series');
                        this.snackbar.showNotification('error', msg, null, { duration: 5000 });
                        return of(TimeSeriesActions.loadTimeSeriesDataError({ symbol, field, error }));
                    })
                );
            })
        )
    );
    batchLoadTimeSeriesData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeSeriesActions.loadBatchTimeSeriesData),
            mergeMap(({ range, requests }) => {
                const request: TimeSeriesDataRequest = { range, inputs: requests };
                return this.timeSeries.getTimeSeriesDatas({ timeSeriesDataRequest: request }).pipe(
                    map(dataResponses => TimeSeriesActions.loadBatchTimeSeriesDataSuccess({ range, requests, responses: dataResponses })),
                    catchError(error => {
                        const msg = ErrorMsgUtilsService.getErrorMsg(error, 'get batch', 'time series');
                        this.snackbar.showNotification('error', msg, null, { duration: 5000 });
                        return of(TimeSeriesActions.loadBatchTimeSeriesDataError({ range, requests, error }));
                    })
                );
            })
        )
    );

    /**
     * Time series data CRUD
     */
    updateData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeSeriesActions.updateTimeSeriesData),
            mergeMap(({ symbol, tenor, field, index, newValue, version }) => {
                const updatedData = { [formatISO(index)]: newValue };
                return this.timeSeries.updateTimeSeriesData({ symbol, requestBody: updatedData, curveDate: null, tenor, field, version }).pipe(
                    map(data => TimeSeriesActions.updateTimeSeriesDataSuccess({ symbol, tenor, field, index, newValue, data })),
                    catchError(error => {
                        this.snackbar.showNotification('error', `Error ${newValue ? 'updating' : 'deleting'} data`, null, { duration: 5000 });
                        return of(TimeSeriesActions.updateTimeSeriesDataError({ symbol, tenor, field, index, newValue, error }));
                    })
                );
            })
        )
    );

    deleteData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeSeriesActions.deleteTimeSeriesData),
            mergeMap(({ symbol, tenor, field, index, version }) => {
                const source = this.symbols.inferSource(symbol),
                    startAndEndIndex = formatISO(index);
                return this.timeSeries
                    .deleteTimeSeriesData({
                        symbol,
                        start: startAndEndIndex,
                        end: startAndEndIndex,
                        tenor,
                        field,
                        curveDate: version
                    })
                    .pipe(
                        map(data => TimeSeriesActions.deleteTimeSeriesDataSuccess({ symbol, tenor, field, index, data })),
                        catchError(error => {
                            const msg = ErrorMsgUtilsService.getErrorMsg(error, 'delete', 'time series');
                            this.snackbar.showNotification('error', msg, null, { duration: 5000 });
                            return of(TimeSeriesActions.deleteTimeSeriesDataError({ symbol, tenor, field, index, error }));
                        })
                    );
            })
        )
    );

    /**
     * Tenor CRUD
     */
    createTenor$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeSeriesActions.createTenor),
            mergeMap(({ symbol, tenor }) => {
                // TODO: update when API available
                return of(null).pipe(
                    map(() => TimeSeriesActions.createTenorSuccess({ symbol, tenor })),
                    catchError(error => {
                        const msg = ErrorMsgUtilsService.getErrorMsg(error, 'create', 'tenor');
                        this.snackbar.showNotification('error', msg, null, { duration: 5000 });
                        return of(TimeSeriesActions.createTenorError({ symbol, tenor, error }));
                    })
                );
            })
        )
    );

    deleteTenor$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeSeriesActions.deleteTenor),
            mergeMap(({ symbol, tenor }) => {
                // TODO: update when API available
                return of(null).pipe(
                    map(() => TimeSeriesActions.deleteTenorSuccess({ symbol, tenor })),
                    catchError(error => {
                        const msg = ErrorMsgUtilsService.getErrorMsg(error, 'delete', 'tenor');
                        this.snackbar.showNotification('error', msg, null, { duration: 5000 });
                        return of(TimeSeriesActions.deleteTenorError({ symbol, tenor, error }));
                    })
                );
            })
        )
    );

    /**
     * Field CRUD
     */
    createField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeSeriesActions.createField),
            mergeMap(({ symbol, tenor, field }) => {
                // TODO: update when API available
                return of(null).pipe(
                    map(() => TimeSeriesActions.createFieldSuccess({ symbol, tenor, field })),
                    catchError(error => {
                        const msg = ErrorMsgUtilsService.getErrorMsg(error, 'create', 'field');
                        this.snackbar.showNotification('error', msg, null, { duration: 5000 });
                        return of(TimeSeriesActions.createFieldError({ symbol, tenor, field, error }));
                    })
                );
            })
        )
    );

    renameField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeSeriesActions.renameField),
            mergeMap(({ symbol, tenor, oldFieldName, newFieldName }) => {
                // TODO: update when API available
                return of(null).pipe(
                    map(() => TimeSeriesActions.renameFieldSuccess({ symbol, tenor, oldFieldName, newFieldName })),
                    catchError(error => {
                        const msg = ErrorMsgUtilsService.getErrorMsg(error, 'rename', 'field');
                        this.snackbar.showNotification('error', msg, null, { duration: 5000 });
                        return of(TimeSeriesActions.renameFieldError({ symbol, tenor, oldFieldName, newFieldName, error }));
                    })
                );
            })
        )
    );

    deleteField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TimeSeriesActions.deleteField),
            mergeMap(({ symbol, tenor, field }) => {
                // TODO: update when API available
                return of(null).pipe(
                    map(() => TimeSeriesActions.deleteFieldSuccess({ symbol, tenor, field })),
                    catchError(error => {
                        const msg = ErrorMsgUtilsService.getErrorMsg(error, 'delete', 'field');
                        this.snackbar.showNotification('error', msg, null, { duration: 5000 });
                        return of(TimeSeriesActions.deleteFieldError({ symbol, tenor, field, error }));
                    })
                );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private timeSeries: TimeseriesService,
        private timeSeriesDetailsFacade: TimeSeriesDetailsFacade,
        private symbols: SymbolService,
        private snackbar: ContextSnackbarService
    ) {}

    /**
     * Helpers
     */
    private formatISO(date: Date): string {
        return format(date, `yyyy-MM-dd'T'HH:mm:ss'Z'`);
    }
}
